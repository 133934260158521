<template>
    <div class="add-new-role" v-if="role.permissions">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.roleCreate')"
            :title="$t('messages.roleCreate')"
            @submitPressed="submitPressed"
        />
        <role-form
            :roleObject="role"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import RoleForm from "./components/RoleForm.vue";
import ListUtils from "@/mixins/ListUtils";

export default {
    mixins: [ListUtils],
    components: {
        TitleBar,
        RoleForm,
    },
    data() {
        return {
            action: null,
            permissionsNames: [],
            role: {
                name: "Roles",
                permissions: [],
                additionalPermissions: [],
            },
        };
    },
    created() {
        this.$Enums.getCollection({params: {pagination: false, cache: true}})
            .then(response => {
                response.data['hydra:member'].forEach(element => {
                    if (element.name === 'user_access_permission') {
                        element.keys.forEach(key => {
                            this.role.permissions.push({
                                name: key,
                                list: false,
                                listAll: false,
                                view: false,
                                create: false,
                                update: false,
                                delete: false,
                            })
                        })
                    }
                    if (element.name === 'additional_user_access_permission') {
                        element.keys.forEach(key => {
                            this.role.additionalPermissions.push({
                                name: key,
                                value: false,
                            })
                        })
                    }
                })
            })
    },
    methods: {
        submitPressed() {
            this.action = "add";
        },
    },
};
</script>
